<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">User Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Company Name" 
          :value="form.company_name"
          :danger="validation.company_name?true:false"
          @input="updateProfileForm({key: 'company_name', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.company_name?true:false">
          {{ validation.company_name?validation.company_name[0]:''}}
        </span>

        <vs-input 
          class="w-full mt-4" 
          label="Citizenship number" 
          :danger="validation.citizenship_number?true:false"
          :value="form.citizenship_number"
          @input="updateProfileForm({key: 'citizenship_number', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.citizenship_number?true:false">
          {{ validation.citizenship_number?validation.citizenship_number[0]:''}}
        </span>

        <vs-input 
          class="w-full mt-4" 
          label="PAN number" 
          :value="form.pan_number"
          @input="updateProfileForm({key: 'pan_number', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.pan_number?true:false">
          {{ validation.pan_number?validation.pan_number[0]:''}}
        </span>

        <vs-input 
          class="w-full mt-4" 
          label="VAT number" 
          :value="form.vat_number"
          @input="updateProfileForm({key: 'vat_number', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.vat_number?true:false">
          {{ validation.vat_number?validation.vat_number[0]:''}}
        </span>

        <vs-input 
          class="w-full mt-4" 
          label="TAN number" 
          :value="form.tan_number"
          @input="updateProfileForm({key: 'tan_number', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.tan_number?true:false">
          {{ validation.tan_number?validation.tan_number[0]:''}}
        </span>

        <vs-input 
          class="w-full mt-4" 
          label="Primary address" 
          :value="form.address_1"
          :danger="validation.address_1?true:false"
          @input="updateProfileForm({key: 'address_1', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.address_1?true:false">
          {{ validation.address_1?validation.address_1[0]:''}}
        </span>
       
        <vs-input 
          class="w-full mt-4" 
          label="Secondary address" 
          :danger="validation.address_2?true:false"
          :danger-text="validation.address_2?validation.address_2[0]:''"
          :value="form.address_2"
          @input="updateProfileForm({key: 'address_2', value: $event})"/>

        <vs-input 
          class="w-full mt-4" 
          label="Primary phone" 
          :danger="validation.phone_1?true:false"
          :danger-text="validation.phone_1?validation.phone_1[0]:''"
          :value="form.phone_1"
          @input="updateProfileForm({key: 'phone_1', value: $event})"/>

        <vs-input 
          class="w-full mt-4" 
          label="Secondary phone" 
          :danger="validation.phone_2?true:false"
          :danger-text="validation.phone_2?validation.phone_2[0]:''"
          :value="form.phone_2"
          @input="updateProfileForm({key: 'phone_2', value: $event})"/>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    vSelect
  },
  mounted() {
    //
  },
  computed: {
    ...mapGetters({
        form: 'user/getProfileFormObj',
        validation: 'validation/getValidationErrors',
    }),
  },
  methods: {
    ...mapActions('user', [
      'updateProfileForm',
    ]),
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
