<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Pan Image</span>
    <file-pond
        name="test"
        ref="pond"
        label-idle="Drop Image here..."
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        instant-upload="false"
        :files="form.pan_image_url != '' ? form.pan_image_url : form.pan"
        @addfile="handleFileAdd" />
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation/dist/filepond-plugin-image-exif-orientation.esm.js';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode/dist/filepond-plugin-file-encode.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginImageExifOrientation, FilePondPluginImagePreview );

export default {
  components: {
    FilePond
  },
  computed: {
    ...mapGetters({
        form: 'user/getProfileFormObj',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions('user', [
      'updateProfileForm'
    ]),
    handleFileAdd(image_name, files) {
      this.updateProfileForm({key: 'image', value:{file: files.file, alt: 'pan', index: 1}});
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
