<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
        <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <ProfileForm></ProfileForm>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <ProfileImage></ProfileImage>
          <PanImage></PanImage>
          <VatImage></VatImage>
          <TanImage></TanImage>
        </div>
       </div>
            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form>
    </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import ProfileForm from './common/ProfileForm';
import { mapGetters, mapActions } from 'vuex'

import {storeImage, updateImage} from '../../../services/image';
import {PROFILE} from '../../../constant/entity-identifier';
import ProfileImage from './common/ProfileImage';
import PanImage from './common/PanImage';
import VatImage from './common/VatImage';
import TanImage from './common/TanImage';
import loaderMixin from '../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    ProfileForm,
    ProfileImage,
    PanImage,
    VatImage,
    TanImage,
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'user/getProfileFormObj',
        images: 'user/getProfileImages',
        alts: 'user/getImageAlts',
    }),
    userId() {
        return this.$route.params.userId;
    },
  },
  created() {
    this.clearValidationErrors();
    this.openLoading();
    this.fetchAndSetProfileById(this.userId).then((res) => this.closeLoading())
      .catch(() => this.closeLoading());
  },
    
  methods: {
    ...mapActions({
      fetchAndSetProfileById: 'user/fetchAndSetProfileById',
      updateProfileAction: 'user/updateProfileAction',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(data, type, method) {
      let formData = new FormData();
      formData.append("_method", method);
      formData.append("entity_id", data.id);
      formData.append("entity", type);
      for (let i = 0; i < this.images.length; i++) {
          formData.append("images[" + i + "]", this.images[i]);
          formData.append("alt[" + i + "]", this.alts[i]);
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.updateProfileAction({
        userId: this.userId,
        data: this.form
      }).then(async response => {
           if(response.status === 201) {
             if(this.images.length > 0) {
               if(response.data.data.media){
                  for (let i = 0; i < this.images.length; i++) {
                    for(let j = 0; i < this.images.length; j++){
                      if(this.alts[i] == response.data.data.media[j].alt){
                        await updateImage(response.data.data.media[j].id, this.prepareForm(response.data.data, PROFILE, 'POST'));
                      }
                    }
                  }
                }else{
                  await storeImage(this.prepareForm(response.data.data, PROFILE, 'POST'));
                }
                this.closeLoading();
             }else{
                this.closeLoading();}
            this.$vs.notify({ 
                text: 'Profile updated successfully.',
                title: 'Profile Updated',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/users');
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
